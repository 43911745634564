<template>
  <page-layout class="ma-24" style="min-width:910px;">
    <!-- 订单详情 -->
    <h3 style="line-height:48px;">基础信息</h3>
    <div
      class="px-20 py-16 mb-15"
      style="line-height:28px;background:rgba(0,0,0,0.10);"
    >
      <!-- <a-row>
        <a-col :span="8">
          <a-row>
            <a-col :span="6">
              订单金额：
            </a-col>
            <a-col :span="18">
              {{ (orderDetails.amount / 100).toFixed(2) }}
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="8">
          <a-row>
            <a-col :span="6">
              应付金额：
            </a-col>
            <a-col :span="18">
              {{ (orderDetails.payable_amount / 100).toFixed(2) }}
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="8"
          ><a-row>
            <a-col :span="6">
              实际金额：
            </a-col>
            <a-col :span="18">
              {{ (orderDetails.payment_amount / 100).toFixed(2) }}
            </a-col>
          </a-row></a-col
        >

        <a-col :span="8">
          <a-col :span="6">
            订单编号：
          </a-col>
          <a-col :span="18">
            {{ orderDetails.order_no }}
          </a-col>
        </a-col>
        <a-col :span="8">
          <a-col :span="6">
            油卡卡号：
          </a-col>
          <a-col :span="18">
            {{ orderDetails.card_no }}
          </a-col>
        </a-col>
        <a-col :span="8"
          ><a-col :span="6">
            订单状态：
          </a-col>
          <a-col :span="18">
            {{ orderDetails.status_desc }}
          </a-col></a-col
        >

        <a-col :span="8">
          <a-col :span="6">
            创建时间：
          </a-col>
          <a-col :span="18">
            {{
              formatToDateTime(orderDetails.order_time, "yyyy-MM-dd hh:mm:ss")
            }}
          </a-col>
        </a-col>
        <a-col :span="8">
          <a-col :span="6">
            创建人：
          </a-col>
          <a-col :span="18">
            {{ orderDetails.station_name }}
          </a-col>
        </a-col>
      </a-row> -->
      <a-row>
        <col-item title="订单金额：">{{
          (orderDetails.amount / 100).toFixed(2)
        }}</col-item>
        <col-item title="应付金额：">{{
          orderDetails.payable_amount
            ? (orderDetails.payable_amount / 100).toFixed(2)
            : "-"
        }}</col-item>
        <col-item title="实际金额：">{{
          (orderDetails.payment_amount / 100).toFixed(2)
        }}</col-item>
        <col-item title="订单编号：">{{ orderDetails.order_no }}</col-item>
        <col-item title="油卡卡号：">{{ orderDetails.card_no }}</col-item>
        <col-item title="订单状态：">{{ orderDetails.status_desc }}</col-item>
        <col-item title="创建时间：">{{
          formatToDateTime(orderDetails.order_time, "yyyy-MM-dd hh:mm:ss")
        }}</col-item>
        <col-item title="创建人：">{{ orderDetails.station_name }}</col-item>
      </a-row>
    </div>
    <h3 style="line-height:48px;">司机信息</h3>
    <div
      class="px-20 py-16 mb-15"
      style="line-height:28px;background:rgba(0,0,0,0.10);"
    >
      <!-- <a-row>
        <a-col :span="8">
          <a-col :span="6">
            司机姓名：
          </a-col>
          <a-col :span="18">
            {{ orderDetails.driver_name }}
          </a-col>
        </a-col>
        <a-col :span="8">
          <a-col :span="6">
            手机号：
          </a-col>
          <a-col :span="18">
            {{ orderDetails.driver_mobile }}
          </a-col>
        </a-col>
        <a-col :span="8"
          ><a-col :span="6">
            开票企业：
          </a-col>
          <a-col :span="18">
            {{ orderDetails.company_name }}
          </a-col></a-col
        >
      </a-row>
      <a-row>
        <a-col :span="8">
          <a-col :span="6">
            车牌号：
          </a-col>
          <a-col :span="18">
            {{ orderDetails.plate_number }}
          </a-col>
        </a-col>
      </a-row> -->
      <a-row>
        <col-item title="司机姓名：">{{ orderDetails.driver_name }}</col-item>
        <col-item title="手机号：">{{ orderDetails.driver_mobile }}</col-item>
        <col-item title="开票企业：">{{ orderDetails.company_name }}</col-item>
        <col-item title="车牌号：">{{ orderDetails.plate_number }}</col-item>
      </a-row>
    </div>
    <h3 style="line-height:48px;">加油/气信息</h3>
    <div
      class="px-20 py-16 mb-15"
      style="line-height:28px;background:rgba(0,0,0,0.10);"
    >
      <!-- <a-row>
        <a-col :span="8">
          <a-col :span="6">
            油品：
          </a-col>
          <a-col :span="18">
            {{ orderDetails.oil_name }}
          </a-col>
        </a-col>
        <a-col :span="8">
          <a-col :span="6">
            加油/气量：
          </a-col>
          <a-col :span="18">
            {{ orderDetails.quantity }}
          </a-col>
        </a-col>
      </a-row> -->
      <a-row>
        <col-item title="油品：">{{ orderDetails.oil_name }}</col-item>
        <col-item title="加油/气量：">{{ orderDetails.quantity }}</col-item>
      </a-row>
    </div>
    <h3 style="line-height:48px;">加油站信息</h3>
    <div
      class="px-20 py-16 mb-15"
      style="line-height:28px;background:rgba(0,0,0,0.10);"
    >
      <!-- <a-row>
        <a-col :span="8">
          <a-col :span="6">
            加油站名：
          </a-col>
          <a-col :span="18">
            {{ orderDetails.station_name }}
          </a-col>
        </a-col>
        <a-col :span="8">
          <a-col :span="6">
            加油站编号：
          </a-col>
          <a-col :span="18">
            {{ orderDetails.station_no }}
          </a-col>
        </a-col>
        <a-col :span="8"
          ><a-col :span="6">
            地区：
          </a-col>
          <a-col :span="18">
            {{ orderDetails.area }}
          </a-col></a-col
        >
      </a-row>
      <a-row>
        <a-col :span="8">
          <a-col :span="6">
            加油枪号：
          </a-col>
          <a-col :span="18">
            {{ orderDetails.gun_no }}
          </a-col>
        </a-col>
      </a-row> -->
      <a-row>
        <col-item title="加油站名：">{{ orderDetails.station_name }}</col-item>
        <col-item title="加油站编号：">{{ orderDetails.station_no }}</col-item>
        <col-item title="地区：">{{ orderDetails.area }}</col-item>
        <col-item title="加油枪号：">{{ orderDetails.gun_no }}</col-item>
      </a-row>
    </div>
  </page-layout>
</template>
<script>
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import colItem from "./components/ColItem.vue";

import { useGasOrderDetailApi } from "@/apis/oil";
import { formatToDateTime } from "@wlhy-web-lib/shared";

export default defineComponent({
  name: "consumer-orders-detail",
  components: {
    colItem
  },
  setup() {
    let route = useRoute();
    let orderDetails = ref({});
    const gasOrderDetail = useGasOrderDetailApi();
    //请求详情数据
    (async () => {
      let id = route.query.id;
      if (!id) return;
      orderDetails.value = await gasOrderDetail({
        id
      });
    })();

    return {
      orderDetails,
      formatToDateTime
    };
  }
});
</script>
