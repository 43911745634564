import { buildApi } from "@/utils/ajax";

const basicApi = buildApi("/portal/company");
const basicApi2 = buildApi("/portal/common");

// 企业油卡账户
export const useGasCardDetailApi = basicApi({
  url: "/gas/card/detail",
  withCredentials: true
});

//充值记录
export const useGasRechargePageApi = basicApi({
  url: "/gas/recharge/page",
  withCredentials: true
});

// 司机油卡账户
export const useGasCardPageApi = basicApi({
  url: "/gas/card/page",
  withCredentials: true
});

//司机下拉列表
/* export const useDriverListApi = basicApi({
  url: "/driver/list",
  withCredentials: true
}); */

//新增司机
export const useGasDriverBindApi = basicApi({
  url: "/gas/driver/bind",
  withCredentials: true
});

//消费订单
export const useGasOrderPageApi = basicApi({
  url: "/gas/order/page",
  withCredentials: true
});

//消费订单导出
export const useGasOrderPageExportApi = basicApi({
  url: "/gas/order/export",
  withCredentials: true
});

//消费订单详情
export const useGasOrderDetailApi = basicApi({
  url: "/gas/order/detail",
  withCredentials: true
});

//划拨记录
export const useGasTransferPageApi = basicApi({
  url: "/gas/transfer/page",
  withCredentials: true
});

//划拨函数
export const useGasTransferApi = basicApi({
  url: "/gas/transfer",
  withCredentials: true
});

//服务商列表
export const useEnumServiceProvideListApi = basicApi2({
  url: "/enum/service_provide_list",
  withCredentials: true
});

//账户类型列表
export const useEnumGasCardTypeListApi = basicApi2({
  url: "/enum/gas_card_type_list",
  withCredentials: true
});

//油品类型列表
export const useEnumGasOilTypeListApi = basicApi2({
  url: "/enum/gas/oil_type_list",
  withCredentials: true
});

//同步司机信息
export const useGasDriverSyncApi = basicApi({
  url: "/gas/driver/sync",
  withCredentials: true
});
