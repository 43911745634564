<template>
  <a-col :span="8">
    <a-row>
      <a-col :span="7" class="text-left">
        {{ title }}
      </a-col>
      <a-col :span="17">
        <slot></slot>
      </a-col>
    </a-row>
  </a-col>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "col-item",
  props: {
    title: String
  }
});
</script>
